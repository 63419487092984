import React, { useState, useEffect, useCallback } from 'react';
import {
  AppBar, Toolbar, Typography, InputBase, Button,Tooltip ,  Popover, List, ListItem, ListItemText, IconButton, Menu, MenuItem,
} from '@mui/material';
import {LocationOn } from '@mui/icons-material';
import { useNavigate} from 'react-router-dom';
import logoImage from '../../assets/images/Update_Logo.png';
import CityDialog from './Locations';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


const AppBarComponent = ({ userDetails = {},onCitySelect,selectedCity1,selectedModel,formattedVariant}) => {
  //console.log(userDetails,"AppBar.....")
  const [selectedCity, setSelectedCity] = useState(selectedCity1 || 'Mysore');
  const navigate = useNavigate();
   const [locationEl, setLocationEl] = useState(null);
  const [openCityDialog, setOpenCityDialog] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const { name = '', email = '', phoneNumber = '' } = userDetails;
   


  const handleLocationClick = (event) => {
    setLocationEl(event.currentTarget);
    setOpenCityDialog(true);
  };

  useEffect(() => {
    if (selectedCity1) {
      onCitySelect(selectedCity1);  // Trigger API call in parent with the selected city
    }
  }, [selectedCity1]);  

  const handleLocationClose = () => {
    setLocationEl(null);
  };

  const handleCloseCityDialog = () => {
    setOpenCityDialog(false);
  };

  const handleProfileMenuClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = () => {
    // Implement logout logic here
    navigate('/');
  };

  
  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName)
    // fetchData(cityName,1);
    onCitySelect(cityName)
    };


  return (
    <div>
      {/* <AppBar position="static"> */}
        <Toolbar className='Appbar'>
          <Typography variant="h6" sx={{ flexGrow: 1 }} style={{cursor:"pointer"}} onClick={() => navigate('/')}>
            <img src={logoImage || ''} alt="logo"  style={{ objectFit: 'contain', display: 'block', maxWidth: '140px' }} />
          </Typography>
          
              <IconButton color="inherit" onClick={handleLocationClick} style={{marginRight:"3%"}}>
      <LocationOn />
      <Typography variant="body1" sx={{ marginLeft: '5px',fontSize:"14px" }}>{selectedCity}</Typography>
      <KeyboardArrowDownIcon sx={{ marginLeft: '5px' }} />
    </IconButton>
        
         {!name && (<Button className='LoginBtn'  style={{textTransform:"capitalize"}} onClick={() => navigate('/login')}>
          {/* <AccountCircleIcon style={{ marginRight: '8px' }} /> */}
          Login</Button>)}
         {name &&  <Tooltip title={name}><Button  style={{
              fontSize: '10px',
              // maxWidth: '100px', // set a max width for the text truncation
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }} endIcon={profileAnchorEl ? <KeyboardArrowUpIcon/>  : <KeyboardArrowDownIcon/>} color="inherit" onClick={handleProfileMenuClick}><AccountCircleIcon style={{ marginRight: '8px',fontSize:"13px" }} />Hello,{name}</Button></Tooltip>}
         {name && ( <Menu
            anchorEl={profileAnchorEl}
            open={Boolean(profileAnchorEl)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={() => navigate('/profile',{state: {name,email,phoneNumber,selectedModel,formattedVariant}})}><ShoppingCartIcon style={{ marginRight: '8px' }} />
            My Orders</MenuItem>
            <MenuItem onClick={handleLogout}><ExitToAppIcon style={{ marginRight: '8px' }} />
            Logout</MenuItem>
          </Menu>)}
        </Toolbar>
      {/* </AppBar> */}

      <CityDialog
        openCityDialog={openCityDialog}
        handleCloseCityDialog={handleCloseCityDialog}
        setSelectedCity={setSelectedCity}
        onCitySelect={handleCitySelect}
      />

    </div>
  );
};

export default AppBarComponent;
