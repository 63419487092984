import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import { Container, Typography, TextField, Button, Box, Grid } from '@mui/material';
import BillingAddress from './BillingAddress';
import PickUpPage from './Pickup'

const PaymentMethodForm = ({selectedModel,  deviceAnswers,formattedVariant,warrantyStatus,goBack,currentValue,rowDetails,answers}) => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [currentPage, setCurrentPage] = useState('PaymentMethodForm');

  // const onSubmit = data => 
 
  const watchAccountNumber = watch("accountNumber");

  const handleContinue = () => {
    if (currentPage === 'PaymentMethodForm') {
      setCurrentPage('BillingAddress');
    }
  };

  const handleBack = () => {
    if (currentPage === 'PickUpPage') {
      setCurrentPage('PickUpPage');
    }
  };

  if(currentPage == "BillingAddress"){
    return <BillingAddress selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} deviceAnswers={deviceAnswers}/>
  }

  if(currentPage == "PickUpPage"){
    return <PickUpPage/>
  }

  return (
    <div >
      {currentPage === "PaymentMethodForm" && (
  <Box sx={{ mt: 4 }}>
  <Typography variant="h5" align="center" gutterBottom>
    Payment method
  </Typography>
  <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
    Please select payment method as per your choice for secure and instant transfers:
  </Typography>

  <Typography variant="h6" gutterBottom>
    Bank Transfer instantly:
  </Typography>
  <Typography variant="body1" gutterBottom>
    Add Bank details:
  </Typography>

  <form>
    <TextField
      fullWidth
      label="Account number"
      {...register("accountNumber", { required: "Account number is required" })}
      error={!!errors.accountNumber}
      helperText={errors.accountNumber?.message}
      margin="normal"
    />
    <TextField
      fullWidth
      label="Confirm Account number"
      {...register("confirmAccountNumber", {
        required: "Please confirm your account number",
        validate: value => value === watchAccountNumber || "Account numbers do not match"
      })}
      error={!!errors.confirmAccountNumber}
      helperText={errors.confirmAccountNumber?.message}
      margin="normal"
    />
    <TextField
      fullWidth
      label="Beneficiary Name"
      {...register("beneficiaryName", { required: "Beneficiary name is required" })}
      error={!!errors.beneficiaryName}
      helperText={errors.beneficiaryName?.message}
      margin="normal"
    />
    <TextField
      fullWidth
      label="IFSC Code"
      {...register("ifscCode", { required: "IFSC code is required" })}
      error={!!errors.ifscCode}
      helperText={errors.ifscCode?.message}
      margin="normal"
    />
    <TextField
      fullWidth
      label="Bank Name"
      {...register("bankName", { required: "Bank name is required" })}
      error={!!errors.bankName}
      helperText={errors.bankName?.message}
      margin="normal"
    />

    <Typography variant="h6" gutterBottom>
      UPI:
    </Typography>
    <Typography variant="body1" gutterBottom>
      Enter your UPI ID:
    </Typography>

    <TextField
      fullWidth
      label="PhonePe Number"
      {...register("phonepe", { required: "PhonePe number is required" })}
      error={!!errors.phonepe}
      helperText={errors.phonepe?.message}
      margin="normal"
    />
    <TextField
      fullWidth
      label="GPay Number"
      {...register("gpay", { required: "GPay number is required" })}
      error={!!errors.gpay}
      helperText={errors.gpay?.message}
      margin="normal"
    />
    <TextField
      fullWidth
      label="Paytm Number"
      {...register("paytm", { required: "Paytm number is required" })}
      error={!!errors.paytm}
      helperText={errors.paytm?.message}
      margin="normal"
    />

    

    <Box sx={{ mt: 2 }}>
    {/* <Button variant="contained"  type="submit" onClick={handleBack}>
        Back
      </Button>  */}
      <Button variant="contained"  type="submit"               
      sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white' }}
 onClick={handleContinue}>
        Continue
      </Button>
    </Box>
  </form>
</Box>
      )}

      
</div>

 
  );
};

export default PaymentMethodForm;
