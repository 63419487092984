import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Button,useMediaQuery,BottomNavigation, BottomNavigationAction,Paper} from '@mui/material';
import { useNavigate, useParams,useLocation} from 'react-router-dom';
import {debounce} from 'lodash';

 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

 
import Navbar from '../commonForms/Navbar';
import AppBarComponent from '../commonForms/AppBar';
import CashonSpotWorks from '../commonForms/CashonSpotWorks';
import VariantSelectionCard from '../screens/Variant';
 import SearchComponent from '../commonForms/SearchComponent';
import NoDataImg from "../../assets/images/NoData.jpeg";
import SellIcon from '@mui/icons-material/Sell';
import { config } from '../../config';
import { handleselectedModel} from '../../redux/WrapperSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Search, Login, Dehaze } from '@mui/icons-material';



const ProductType = () => {
  const { productType } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const { city,brandName,name,email,phoneNumber } = location.state || { city: '', page: 1, };
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [showVariantCard, setShowVariantCard] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Initialize currentPage
  const [loading, setLoading] = useState(false);
  const [allDataFetched, setAllDataFetched] = useState(false); // New state to track if all data has been fetched
  const[SeriesList,setSeriesList] = useState([]);
  const [userDetails,setUserDetails] = useState({});
  const[selectedCity,setSelectedCity] = useState("Mysore")
  const navigate = useNavigate();
  const title = productType.replace('-', ' ');
  const dataFromBackend = location.state?.data || [];
  const [formattedVariant, setFormattedVariant] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

// search section


 
const formUrls = {
  'repair phone':'https://forms.gle/8DKs2vx2grkdMLU27',  // Replace with actual URL
  'buy phone': 'https://forms.gle/s7p1z8LYbo3Y1zEk8',    // Replace with actual URL
  'buy laptop': 'https://forms.gle/oD1XDbQAnsMieCFW6'     // Replace with actual URL
};

useEffect(() => {
  // Ensure the effect runs only once
  if (formUrls[title]) {
    // Open the corresponding Google Form URL in a new tab
    const newTab = window.open(formUrls[title], '_blank');
    
    // Focus on the new tab and close previous tabs if any
    if (newTab) {
      newTab.focus();
    }
  }  
}, []);
  

  useEffect(() => {
    if (dataFromBackend.length) {
      setBrandsData(dataFromBackend);
 
    }
  }, [dataFromBackend]);


  useEffect(() => {
    if (brandName) {
      handleBrandClick(brandName);
    }
  }, [brandName]);


  const fetchData = (city, page, brand) => {
    if (allDataFetched) return; // Prevent API call if all data is already fetched
    setLoading(true);
    let url = `${config.Api}sheet-data?City=${city}&page=${page}&limit=10`;

    if (brand) {
      url += `&brand=${brand}`;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.length === 0) {
        setAllDataFetched(true); // Set flag if no more data is returned
      } else {
        if (page === 1) {
          setBrandsData(data); // Replace data for the first page
        } else {
          setBrandsData(prevData => [...prevData, ...data]); // Append data for subsequent pages
        }
      }
      setLoading(false);
    })
    .catch(() => {
       setLoading(false);
    }
  );
  };

  // fetch Series

  const fetchSeriesList = (city,brand) => {
    // if (allDataFetched) return; // Prevent API call if all data is already fetched
    setLoading(true);
    let url = `${config.Api}series-data?City=${city}`;

    if (brand) {
      url += `&brand=${brand}`;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.length === 0) {
       setSeriesList([]) 
      } else {
       setSeriesList(data); // Append data for subsequent pages
         
      }
      setLoading(false);
    })
    .catch((error) => {
       setLoading(false);
    });
  };

  // fetch MobileBrands by series

  const fetchBrandsBySeries = (city, series,brand) => {
    setLoading(true);
    let url = `${config.Api}brandsbyseries?City=${city}`;
  
    if (series) {
      url += `&series=${encodeURIComponent(series)}`;
    }
    
    if (brand) {
      url += `&brand=${encodeURIComponent(brand)}`;
    }
  
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.length === 0) {
        setBrandsData([]);
      } else {
        setBrandsData(data); // Set the list of unique MobileBrands
      }
      setLoading(false);
    })
    .catch(() => {
       setLoading(false);
    });
  };
  
  const handleFormData = (formData) => {
     const {name,email,phoneNumber} = formData;
    setUserDetails({ name, email, phoneNumber });
 
  };
  
  const handleMoreClick = () => {
    if (allDataFetched) return; // Prevent fetching more data if all data is already fetched

    const nextPage = currentPage + 1; // Increment currentPage correctly
    setCurrentPage(nextPage); // Update currentPage in state
    fetchData(city, nextPage, selectedBrand); // Use nextPage instead of currentPage
  };
  const handleScroll = useCallback(
    debounce(() => {
      if (loading || allDataFetched) return; // Prevent fetching if already loading or all data fetched
  
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      // Trigger when scroll reaches 80% of the page height
      if (scrollTop + clientHeight >= scrollHeight * 0.4) {
        handleMoreClick();
      }
    }, 200),
    [loading, currentPage, city, selectedBrand, allDataFetched]
  );
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

 const MobileBrands = [
  { name: 'Apple Iphone', image: 'https://i.pinimg.com/736x/18/1b/b9/181bb9da20d9c75610a671edb5c8bb1f.jpg' },
  { name: 'Samsung', image: 'https://i.ibb.co/BN8PKw0/pngimg-com-samsung-logo-PNG9.png' },
  {name : "One Plus", image : "https://logos-world.net/wp-content/uploads/2023/03/OnePlus-Logo.jpg"},
  { name: 'Realme', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Realme_logo.svg/2560px-Realme_logo.svg.png' },
  { name: 'OPPO', image: 'https://www.michael-young.com/wp-content/uploads/2020/03/Oppo-Logo.png' },
  { name: 'VIVO', image: 'https://1000logos.net/wp-content/uploads/2022/02/Vivo-Logo.jpg' },
  { name: 'Xiaomi', image: 'https://madaboutkitchen.in/wp-content/uploads/2021/04/Xiaomi-redmi-logo.jpg' },
  { name: 'Poco', image:'https://i.ibb.co/1s5F7YK/POCO-Emblem.png' },
  { name: 'IQOO', image:'https://images.seeklogo.com/logo-png/46/1/iqoo-logo-png_seeklogo-469682.png' },
  { name: 'Nothing', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Nothing_Logo.webp/640px-Nothing_Logo.webp.png' },
  { name: 'Google', image: 'https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png' },
  { name: 'Motorola', image: 'https://logolook.net/wp-content/uploads/wordpress-popular-posts/28151-featured-225x127.png' },
  { name: 'NOKIA', image: 'https://static.vecteezy.com/system/resources/previews/020/927/540/non_2x/nokia-logo-brand-phone-symbol-black-design-finland-mobile-illustration-free-vector.jpg' },
  { name: 'ASUS', image: 'https://logos-world.net/wp-content/uploads/2020/07/Asus-Logo.png' },
  ]; 
const LaptopBrands = [
    { name: 'HP', image: 'https://1000logos.net/wp-content/uploads/2017/02/HP-Logo-2012.png' },
    { name: 'ASUS', image: 'https://e7.pngegg.com/pngimages/15/120/png-clipart-acer-logo-laptop-hewlett-packard-dell-acer-aspire-acer-palmatum-thunb-electronics-company.png' },
    {name : "LENOVO", image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8aMHBJHhHuqbjON0jCBO4tI784w97tMiK8A&s"},
    { name: 'MI', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfFi7xsTX2235mxkXtFgwfaIo5kkk0UF49yQ&s' },
    { name: 'OTHER LAPTOP', image: 'https://images.anandtech.com/doci/9798/IMG_6713_678x452.jpg' },
    { name: 'REALME', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Realme_logo.svg/2560px-Realme_logo.svg.png' },
    { name: 'TOSHIBA', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/TOSHIBA_Logo.png/1200px-TOSHIBA_Logo.png' },
    { name: 'DELL', image: 'https://w7.pngwing.com/pngs/824/437/png-transparent-dell-logo-hewlett-packard-brand-high-definition-video-hewlett-packard-blue-text-trademark-thumbnail.png' },
    { name: 'MICROSOFT', image: 'https://www.gmevents.ae/wp-content/uploads/2024/03/microsoft-logo-300x300.png' }
  ];
const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
    setSelectedSeries(null);
    dispatch(handleselectedModel(null));
    setShowVariantCard(false);
    setCurrentPage(1); // Reset the page number when changing brand
    setAllDataFetched(false); // Reset allDataFetched flag
    fetchData(city, 1, brand);
    fetchSeriesList(city,brand);
    
    
  };

  const handleSeriesClick = (series) => {
    setSelectedSeries(series);
    dispatch(handleselectedModel(null));
    setShowVariantCard(false);
    fetchBrandsBySeries(city,series,selectedBrand)
  };

  const handleModelClick = (model) => {
    dispatch(handleselectedModel(model));
    setShowVariantCard(true);
    setSelectedSeries(null);

  };

  const handleBackToBrandsClick = () => {
    setSelectedBrand(null);
    setSelectedSeries(null);
    dispatch(handleselectedModel(null));
    setShowVariantCard(false);
    setCurrentPage(1);
    setAllDataFetched(false);
  };

  const handleBackToSeriesClick = () => {
    dispatch(handleselectedModel(null));
    setShowVariantCard(false);
    setSelectedSeries(null);
    fetchData(city, 1, selectedBrand);
    fetchSeriesList(city,selectedBrand);
  };

  const handleBackClick = () => {
    navigate('/', { state: { name: userDetailsToPass?.name,email:userDetailsToPass?.email,phoneNumber: userDetailsToPass?.phoneNumber} });
    
  };
  const getSeriesByBrand = (brandName) => {
    // Filter brandsData for the selected brand
    const brand = SeriesList.filter(brand => brand["Brand name"] === brandName);
  
    // Extract series from the filtered brand data
    const series = brand.map(item => item.Series);
  
    // Check if all series are "N/A" or if the series array is empty
    const hasValidSeries = series.some(seriesValue => seriesValue !== "N/A" && seriesValue !== undefined);
  
    if (!hasValidSeries) {
      return [];
    }
  
    // Extract unique series values, excluding "N/A" and undefined
    const uniqueSeries = [...new Set(series.filter(seriesValue => seriesValue !== "N/A" && seriesValue !== undefined))];
  
    return uniqueSeries;
  };
  

  const getModelsBySeries = (seriesName) => {
    const seriesData = brandsData.filter(brand => brand["Series"] === seriesName);
    return seriesData ? seriesData : [];
  };

  const getModelsByBrand = (brandName) => {
    const brandData = brandsData.filter(brand => brand["Brand name"] === brandName);
    return brandData;
  };

  const handleSearchSelect = (selectedItem) => {
    dispatch(handleselectedModel(selectedItem))
    setShowVariantCard(true);
   };


  const userDetailsToPass = userDetails.name || userDetails.email || userDetails.phoneNumber ? userDetails : { name: name, email: email, phoneNumber: phoneNumber }; // Default or fallback data

  // Destructure values from locationState or use formData if locationState is empty
  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName);
    };

// Map title to the corresponding brands array
const brandMap = {
  ["sell phone"]: MobileBrands,
  ["sell laptop"]: LaptopBrands,
  // Add other categories here if needed
};

const brands = brandMap[title] || [];


const handleFormattedVariantChange = (variant) => {
  setFormattedVariant(variant);
};

  return (
    <>
      <AppBarComponent userDetails={userDetailsToPass} onCitySelect={handleCitySelect} selectedCity1={city}  formattedVariant={formattedVariant}/>
      {!isMobile && (<Navbar/>)}      
      <Box sx={{ mt: 4 }}>
          {/* Search bar */}
         {(!showVariantCard) && <SearchComponent onSelect={handleSearchSelect}/>}
          {(!selectedBrand && !showVariantCard) ? (
          <>
           <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
           <Button
              variant="outlined"
              color="primary"
              onClick={handleBackClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
                padding: '6px 12px',
                borderRadius: '20px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
                },
              }}
>
  <ArrowBackIcon sx={{ mr: 1 }} />
  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
    Go Back
  </Typography>
</Button>
</Box>
<Box display="flex" alignItems="center" justifyContent="center">
  <SellIcon sx={{ fontSize: 'inherit', mr: 1 }} />
  <Typography className="sell-old" variant="h2" align="center">
    Sell Old {title} for Instant Cash
  </Typography>
</Box>            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
              {brands.map((brand) => (
                <Grid item xs={4} sm={4} md={2} key={brand.name}>
                  <Card 
                  onClick={() => handleBrandClick(brand.name)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'column', // Ensure content stacks vertically
                  }}
                  >
                    <CardActionArea
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column', // Center the content vertically inside the card
                        height: '100%',
                      }}
                     >
                      <CardMedia
                        component="img"
                        alt={brand.name}
                        height="100"
                        image={brand.image}
                        title={brand.name}
                        sx={{
                          objectFit: 'contain',
                          maxWidth: '60px',
                          height: '100px', // Keep the height uniform
                        }}
                        />
                     
                    </CardActionArea>
                  </Card>

                </Grid>
              ))}
            </Grid>
            {isMobile && (
        <Paper 
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1300, // Ensure it's above other elements
          }} 
          elevation={3}
        >
           <BottomNavigation>
       <BottomNavigationAction label="Search" icon={<Search />} />
      <BottomNavigationAction label="Login" icon={<Login />} />
      <BottomNavigationAction
        label="Menu"
        icon={<Navbar />} // Render Navbar directly inside the action
      />
    </BottomNavigation>
        </Paper>
      )}
            <CashonSpotWorks />
          </>
        ) : 
        
        (
               <>
              <Box sx={{ mt: 4 }}>

              {
              
              (!selectedSeries && !selectedModel) ?  (
  <>
    {getSeriesByBrand(selectedBrand)?.length > 0 ? (
      <>
       <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
       <Button
              variant="outlined"
              color="primary"
              onClick={handleBackToBrandsClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
                padding: '6px 12px',
                borderRadius: '20px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
                },
              }}
>
  <ArrowBackIcon sx={{ mr: 1 }} />
  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
    Go Back
  </Typography>
</Button>
   
       
     </Box>
      <Box sx={{ mt: 4 }}>
        <Typography style={{fontWeight:"bold"}} variant="body" align="center" >Select Series for {selectedBrand}</Typography>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2, position: 'relative' }}>
          {getSeriesByBrand(selectedBrand)?.map((series) => (
            <Grid item key={series}>
              <Button
                variant="contained"
                sx={{ backgroundColor: 'grey', color: 'white' }}
                onClick={() => handleSeriesClick(series)}
              >
                {series}
              </Button>
            </Grid>
          ))}
        </Grid>
       </Box>
       </>
    ) : (
      <>
   <Button
              variant="outlined"
              color="primary"
              onClick={handleBackToBrandsClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
                padding: '6px 12px',
                borderRadius: '20px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
                },
              }}
>
  <ArrowBackIcon sx={{ mr: 1 }} />
  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
    Go Back
  </Typography>
</Button>
     
      </>
      
    )}

   {getModelsByBrand(selectedBrand).length > 0 && (<Typography variant="h6" fontWeight={"bold"} align="center" sx={{mt:4}} className='selectmodelseriesfor'>Select Model for {selectedBrand}</Typography>)}
   
    <Grid container spacing={2} justifyContent="center" sx={{ mt: 2, position: 'relative' }}>
    
      {getModelsByBrand(selectedBrand).length > 0 ? (
        getModelsByBrand(selectedBrand).map((model, index) => (
          <Grid item xs={4} sm={4} md={3} key={index}>
            <Card onClick={() => handleModelClick(model)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={model.Model}
                  height="140"
                  image={model[" Image URL"]}
                  title={model.Model}
                  style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px',padding:"4%" }}
                />
                <CardContent style={{textAlign:"center"}}>
                  <Typography variant="body" align="center"className='brandnamemodel' >
                    {`${model["Brand name"]} ${model.Model}`}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))
      ) : (
        <Typography variant="body1" align="center" fontWeight={"bold"}  sx={{ mr: 2,mb:3 }}>
          <img src={NoDataImg} alt="" style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px',padding:"1%" }}/>
          No models found for {selectedBrand}
        </Typography>
      )}
    </Grid>
   
  </>
) 

:

( !showVariantCard &&
  <Box sx={{ mt: 4 }}>

    
<Button
              variant="outlined"
              color="primary"
              onClick={handleBackToSeriesClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
                padding: '6px 12px',
                borderRadius: '20px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
                },
              }}
>
  <ArrowBackIcon sx={{ mr: 1 }} />
  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
    Go Back
  </Typography>
</Button>
   
  <Typography variant="p" align="center" fontWeight={"bold"}>Selected Series: {selectedSeries}</Typography>
  <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
    {getModelsBySeries(selectedSeries)?.map((model) => (
      <Grid item xs={4} sm={4} md={3} key={model}>
        <Card onClick={() => handleModelClick(model)}>
        <CardActionArea >
            <CardMedia
              component="img"
              alt={model["Brand name"]}
              height="140"
              image={model[" Image URL"]}
              title={model["Brand name"]}
              style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}
            />
            <CardContent>
              <Typography variant="body1" align="center" className='brandnamemodel'>
              {`${model["Brand name"]} ${model.Model}`}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
</Box>
)




}
{/* // Variant Section and Exact */}
                  

                       {(selectedModel && showVariantCard) && (
                        <>
              <Button
              variant="outlined"
              color="primary"
              onClick={handleBackToSeriesClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
                padding: '6px 12px',
                borderRadius: '20px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
                },
              }}
>
  <ArrowBackIcon sx={{ mr: 1 }} />
  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
    Go Back
  </Typography>
</Button>
<VariantSelectionCard 
userLogDetails={{name,email,phoneNumber}}
 onFormData={handleFormData}
 onFormattedVariantChange={handleFormattedVariantChange}
  />
                          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            
                          </Box>
                        </>
                      )} 
                      </Box>
              </>
            
          
        )}



 
      </Box>
      
    </>
  );
};

export default ProductType;
