import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
// import './StickyIcons.css'; // Import the CSS file for styling

const StickyIcons = () => {
  return (
    <div className="sticky-container">
      <a href="mailto:sellatcashonspot.in@gmail.com" target="_blank" rel="noopener noreferrer">
        <div className="icon">
          <FaEnvelope size={16} />
        </div>
      </a>
      <a href="tel:+918296961413" target="_blank" rel="noopener noreferrer">
            <div className="icon">
                <FaPhoneAlt size={16} />
            </div>
            </a>
      <a href="https://wa.me/8296961413" target="_blank" rel="noopener noreferrer">
        <div className="icon">
          <FaWhatsapp size={16} />
        </div>
      </a>
    </div>
  );
};

export default StickyIcons;
