// src/features/auth/wrapperSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

const initialState = {
  rowDetails: {},
  formattedVariant : "",
  selectedModel : null,
  city:"Mysore",
  currentValue:"",

  
  
};

const wrapperSlice = createSlice({
  name: 'wrapper',
  initialState,
  reducers: {
    handleMount: (state, action) => {
       state.rowDetails = action.payload;
    },
    handlePickupDetails: (state, action) => {
     state.handlePickupDetails = action.payload;
    
  },
  
  handleselectedModelDetails : (state,action) => {
    state.selectedModelDetails = action.payload;
  },
  handleformattedVariant : (state,action) => {
    state.formattedVariant = action.payload
  },
  handleselectedModel : (state,action) => {
    state.selectedModel = action.payload
  },
  handlecity : (state,action) => {
    state.city = action.payload
  },
  handlecurrentValue : (state,action) => {
    state.currentValue = action.payload;
  }
 
  },
});

export const {
  handleMount,
  handlePickupDetails,
  handleselectedModelDetails,
  handleformattedVariant,
  handlecity,
  handleselectedModel,
  handlecurrentValue
} = wrapperSlice.actions;
export default wrapperSlice.reducer;
